import * as types from '../mutation-types';
import JsSIP from 'jssip';
import { shallowRef } from 'vue';
import { conversationUrl, frontendURL } from 'dashboard/helper/URLHelper';
import router from 'dashboard/routes';
import ConversationApi from 'dashboard/api/conversations';
import ContactAPI from 'dashboard/api/contacts';
import MessageApi from 'dashboard/api/inbox/message';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import i18n from 'dashboard/i18n';

function loadAudio(name, loop) {
  const audio = new Audio(`/audio/dashboard/${name}.mp3`);
  audio.loop = loop;
  return shallowRef(audio);
}

export const state = {
  userRequestedStatus: 'offline', // offline, online
  status: 'offline', // offline, connecting, connected, dialing, ringing, speaking
  userAgent: null,
  session: null,
  keepAliveInterval: null,
  streams: { local: null, remote: null },
  callTimer: null,
  callDuration: null,
  ringtone: loadAudio('ringtone', true),
  busyTone: loadAudio('busy'),
  ringingTone: loadAudio('ringing', true),
  hangupDing: loadAudio('hangup'),
  connectDing: loadAudio('ding'),
  holdStatus: false,
  micMuted: false,
  speakerSilent: false,
};

/* eslint-disable no-console */
function log(message, ...details) {
  console.log(`[SIP] ${message}`, ...details);
}
/* eslint-enable no-console */

function setupSessionListeners(session, dispatch, commit) {
  // session.on('sdp', e => log(`SDP: ${e.sdp}`));
  session.on('icecandidate', e => {
    log(`ICE candidate: ${e.candidate ? e.candidate.candidate : 'null'}`);
  });
  session.on('iceconnectionstatechange', () => {
    log(`ICE connection state: ${session.connection.iceConnectionState}`);
  });
  session.on('ended', e => {
    log('Call ended', e);
    dispatch('callEnded', { reason: 'hangup' });
  });
  session.on('failed', e => {
    log('Call failed', e);
    dispatch('callEnded', { reason: 'failed' });
  });
  session.on('accepted', async e => {
    log('Call accepted', e);
    await dispatch('attachMediaStreams');
    await dispatch('callStarted');
    if (session.direction === 'incoming') {
      await dispatch('setupConversation');
      await dispatch('postCallStartedMessage');
    }
    commit(types.default.SET_PHONE_STATUS, 'speaking');
  });
  session.on('hold', e => {
    log(
      e.originator === 'local'
        ? 'Call put on hold'
        : 'Call put on hold by remote party'
    );
    commit(types.default.SET_PHONE_HOLD, e.originator);
  });
  session.on('unhold', e => {
    log(
      e.originator === 'local'
        ? 'Call resumed from hold'
        : 'Call resumed from hold by remote party'
    );
    commit(types.default.SET_PHONE_HOLD, false);
  });
}

function parseSipContact(request) {
  try {
    return request.parseHeader('Contact')?.uri?.user;
  } catch {
    return null;
  }
}

function parseSipRequestHeader(request, header) {
  const value = request.getHeader(header);
  if (!value) return null;
  try {
    const parsed = JsSIP.Grammar.parse(value, 'From');
    return parsed?.uri?.user;
  } catch {
    return null;
  }
}

function parseCallInfo(request) {
  if (!request) return {};
  try {
    let callInfo = request.getHeader('X-Call-Info');
    if (!callInfo) {
      return {};
    }
    return JSON.parse(callInfo);
  } catch (e) {
    log('Error parsing call info', e);
    return {};
  }
}

function parseRemotePartyNumber(callInfo, session, request) {
  return (
    callInfo?.caller_number ||
    parseSipContact(request) ||
    parseSipRequestHeader(request, 'P-Asserted-Identity') ||
    parseSipRequestHeader(request, 'Remote-Party-ID') ||
    session.remote_identity.uri.user
  );
}

async function conversationFromSessionId(request) {
  let sessionId = request.getHeader('X-Session-ID');
  if (!sessionId) {
    return null;
  }
  const { data: response } = await ConversationApi.search({
    identifier: sessionId,
  });
  if (!response.data?.payload?.length) {
    return null;
  }
  return response.data.payload[0];
}

function ensureValidPhoneNumber(phoneNumber) {
  if (phoneNumber && phoneNumber.match(/^\+[1-9]\d{1,14}$/)) {
    return phoneNumber;
  }
  return null;
}

export const getters = {
  getSession($state) {
    return $state.session;
  },
  getStreams($state) {
    return $state.streams;
  },
  getUserStatus($state) {
    return $state.userRequestedStatus;
  },
  getStatus($state) {
    return $state.status;
  },
  getCallDuration($state) {
    return $state.callDuration;
  },
  getHoldStatus($state) {
    return $state.holdStatus;
  },
  getMicMuted($state) {
    return $state.micMuted;
  },
  getSpeakerSilent($state) {
    return $state.speakerSilent;
  },
  available($state, $getters, rootState, rootGetters) {
    const accountId = rootGetters.getCurrentAccountId;
    let account = rootGetters['accounts/getAccount'](accountId);
    return (
      account.sip_host &&
      account.sip_host.length !== 0 &&
      account.ws_url &&
      account.ws_url.length !== 0
    );
  },
  configured($state, $getters, rootState, rootGetters) {
    if (!$getters.available) {
      return false;
    }
    let { username, password } =
      rootGetters['userPhoneCredentials/getCredentials'];
    return username && password && username.length > 0 && password.length > 0;
  },
};

const SESSION_OPTIONS = { mediaConstraints: { audio: true, video: false } };

export const actions = {
  connect: async function connect({
    state: _state,
    dispatch,
    commit,
    rootGetters,
  }) {
    const accountId = rootGetters.getCurrentAccountId;
    let account = rootGetters['accounts/getAccount'](accountId);
    let creds = rootGetters['userPhoneCredentials/getCredentials'];
    commit(types.default.SET_USER_PHONE_STATUS, 'online');
    const sipUri = `sip:${creds.username}@${account.sip_host}`;
    const socket = new JsSIP.WebSocketInterface(account.ws_url);
    const configuration = {
      sockets: [socket],
      uri: sipUri,
      password: creds.password,
    };

    const ua = new JsSIP.UA(configuration);
    await commit(types.default.SET_PHONE_UA, ua);

    ua.on('connecting', () => {
      log('Connecting to SIP server');
      commit(types.default.SET_PHONE_STATUS, 'connecting');
    });
    ua.on('connected', () => {
      log('Connected to SIP server');
      _state.connectDing.play();
      dispatch('keepAliveStart');
    });
    ua.on('disconnected', () => {
      log('Disconnected from SIP server');
      _state.ringtone.pause();
      _state.ringingTone.pause();
      _state.connectDing.play();
      commit(types.default.SET_PHONE_STATUS, 'offline');
      dispatch('keepAliveStop');
    });
    ua.on('registered', () => {
      log('Registered with SIP server');
      commit(types.default.SET_PHONE_STATUS, 'connected');
    });
    ua.on('unregistered', () => {
      log('Unregistered from SIP server');
      commit(types.default.SET_PHONE_STATUS, 'connecting');
    });
    ua.on('registrationFailed', e => {
      log(`Registration failed: ${JSON.stringify(e)}`);
      dispatch('disconnect');
    });
    ua.on('newRTCSession', async data => {
      if (_state.session) {
        log('A session is already active, terminating the new session');
        try {
          data.session.terminate();
        } catch (e) {
          log('Error terminating the new session', e);
        }
        return;
      }

      const { session, request } = data;
      log('New RTC session', session, request);
      window.sipData = data;
      setupSessionListeners(session, dispatch, commit);
      await commit(types.default.SET_PHONE_SESSION, { session, request });
      await dispatch('findOrCreateContact');
      if (session.direction === 'incoming') {
        await commit(types.default.SET_PHONE_STATUS, 'ringing');
        _state.ringtone.currentTime = 0;
        _state.ringtone.play();
      } else {
        _state.ringingTone.currentTime = 0;
        _state.ringingTone.play();
        await dispatch('setupConversation');
        await dispatch('postCallStartedMessage');
        await commit(types.default.SET_PHONE_STATUS, 'dialing');
        log('Setting up outgoing call');
      }
    });

    log('Starting user agent');
    ua.start();
  },
  keepAliveStart: async function keepAliveStart({ state: _state, commit }) {
    const sipUrl = _state.userAgent.configuration.uri;
    const interval = setInterval(() => {
      _state.userAgent.sendOptions(sipUrl, null, {});
    }, 30_000);
    commit(types.default.SET_PHONE_KEEPALIVE_INTERVAL, interval);
  },
  keepAliveStop: async function keepAliveStop({ state: _state }) {
    if (_state.keepAliveInterval) {
      clearInterval(_state.keepAliveInterval);
    }
  },
  disconnect: async function disconnect({ state: _state, commit }) {
    commit(types.default.SET_USER_PHONE_STATUS, 'offline');
    if (_state.userAgent) {
      log('Disconnecting from SIP server...');
      await commit(types.default.SET_PHONE_UA, null);
    }
    _state.ringtone.pause();
    _state.ringingTone.pause();
  },
  acceptCall: async function acceptCall({ state: _state, dispatch }) {
    try {
      _state.session.answer(SESSION_OPTIONS);
      log('Incoming call answered');
    } catch (e) {
      log('Failed to answer incoming call, aborting', e);
      try {
        _state.session.terminate();
      } catch {
        /* ignored */
      }
      dispatch('callEnded', { reason: 'error' });
    }
  },
  callStarted: async function callStarted({ state: _state, commit }) {
    _state.ringtone.pause();
    _state.ringingTone.pause();
    let seconds = 0;
    const timer = setInterval(() => {
      seconds += 1;
      commit(types.default.SET_PHONE_CALL_DURATION, seconds);
    }, 1000);
    commit(types.default.SET_PHONE_CALL_TIMER, timer);
    commit(types.default.SET_PHONE_CALL_DURATION, 0);
  },
  callEnded: async function callEnded(
    { state: _state, commit, dispatch },
    { reason }
  ) {
    _state.ringtone.pause();
    _state.ringingTone.pause();
    clearInterval(_state.callTimer);
    let session = _state.session;
    commit(types.default.SET_PHONE_STATUS, 'connected');
    commit(types.default.SET_PHONE_SESSION, {});
    commit(types.default.SET_PHONE_CALL_DURATION, null);
    commit(types.default.SET_PHONE_CONVERSATION, null);
    if (reason === 'failed') {
      if (session.direction === 'incoming') {
        _state.hangupDing.play();
      } else {
        _state.busyTone.play();
      }
      dispatch('postActivityMessage', { key: 'PHONE_CTRLS.MESSAGES.FAILED' });
    } else if (reason === 'hangup') {
      _state.hangupDing.play();
      dispatch('postActivityMessage', { key: 'PHONE_CTRLS.MESSAGES.ENDED' });
    }
  },
  attachMediaStreams: async function attachMediaStreams({
    state: _state,
    commit,
  }) {
    const localTracks = _state.session.connection
      .getSenders()
      .map(sender => sender.track);
    const remoteTracks = _state.session.connection
      .getReceivers()
      .map(receiver => receiver.track);

    let local = null;
    if (localTracks.length > 0) {
      log('attachMediaStreams: localTracks', localTracks);
      // const localAudio = document.getElementById('localAudio');
      // localAudio.srcObject = new MediaStream(localTracks);
      local = new MediaStream(localTracks);
    } else {
      log('No local stream found');
    }

    let remote = null;
    if (remoteTracks.length > 0) {
      log('attachMediaStreams: remoteStream', remoteTracks);
      // const remoteAudio = document.getElementById('remoteAudio');
      // remoteAudio.srcObject = new MediaStream(remoteTracks);
      remote = new MediaStream(remoteTracks);
    } else {
      log('No remote stream found');
    }

    commit(types.default.SET_PHONE_STREAMS, { local, remote });
  },
  makeCall: async ({ state: _state }, { number: numberToDial }) => {
    if (_state.session) {
      log('A call is already in progress');
    } else {
      log(`Calling ${numberToDial}...`);
      _state.userAgent.call(numberToDial, SESSION_OPTIONS);
    }
  },
  hangup: async function hangup({ state: _state, dispatch }) {
    if (_state.session) {
      log('Hanging up the call');
      try {
        _state.session.terminate();
      } catch (e) {
        dispatch('callEnded', { reason: 'error' });
        log('Unable to hang up the call.', e);
      }
      _state.ringtone.pause();
      _state.ringingTone.pause();
    }
  },
  toggleHold: async function hold({ state: _state }) {
    if (!_state.session) {
      log('Call is not in progress');
      return;
    }
    if (_state.holdStatus === 'remote') {
      log('Call is held remotely cannot put on hold');
      return;
    }
    if (_state.holdStatus === 'local') {
      _state.session.unhold();
      log('Call off hold');
    } else {
      _state.session.hold();
      log('Call put on hold');
    }
  },
  toggleMute: async function toggleMute({ state: _state, commit }) {
    if (!_state.session) {
      log('Call is not in progress');
      return;
    }
    if (_state.micMuted) {
      _state.session.unmute();
      log('Microphone unmuted');
      commit(types.default.SET_PHONE_MIC_MUTED, false);
    } else {
      _state.session.mute();
      log('Microphone muted');
      commit(types.default.SET_PHONE_MIC_MUTED, true);
    }
  },
  toggleSilence: async function toggleSilence({ state: _state, commit }) {
    if (!_state.session) {
      log('Call is not in progress');
      return;
    }
    commit(types.default.SET_PHONE_SPEAKER_SILENT, !_state.speakerSilent);
  },
  transfer: async function transfer({ state: _state }, { target }) {
    if (_state.session && target) {
      log(`Transferring call to ${target}...`);
      _state.session.refer(target);
    }
  },
  findOrCreateContact: async ({ state: _state, dispatch, commit }) => {
    const callerPhoneNumber = await _state.otherPartyNumber;
    let contact = await dispatch('contacts/filterByNumber', callerPhoneNumber, {
      root: true,
    });
    if (contact) {
      log('Using existing contact', contact);
      return commit(types.default.SET_PHONE_CONTACT, contact);
    }
    const phoneNumberForContact = ensureValidPhoneNumber(callerPhoneNumber);
    const response = await ContactAPI.create({
      name: `tel: ${callerPhoneNumber}`,
      phone_number: phoneNumberForContact,
    });
    log('Created new contact', response);
    return commit(
      types.default.SET_PHONE_CONTACT,
      response.data.payload.contact
    );
  },
  setupConversation: async ({ state: _state, dispatch }) => {
    if (_state?.callInfo?.conversation_id) {
      const { data: conversationFromHeader } = await ConversationApi.show(
        _state.callInfo.conversation_id
      );
      dispatch('openConversation', conversationFromHeader);
      return;
    }
    let conversationFromSession = await conversationFromSessionId(
      _state.request
    );
    if (conversationFromSession) {
      dispatch('openConversation', conversationFromSession);
      return;
    }
    log('Conversation call info not set');
  },
  openConversation: async ({ commit }, conversation) => {
    await commit(types.default.SET_PHONE_CONVERSATION, conversation);
    const path = frontendURL(
      conversationUrl({
        accountId: conversation.account_id,
        id: conversation.id,
      })
    );
    log('Opening conversation', path, conversation);
    return router.push(path);
  },
  postCallStartedMessage: async ({ state: _state, dispatch }) => {
    if (_state.session.direction === 'incoming') {
      return dispatch('postActivityMessage', {
        key: 'PHONE_CTRLS.MESSAGES.ANSWERED',
      });
    }
    return dispatch('postActivityMessage', {
      key: 'PHONE_CTRLS.MESSAGES.INITIATED',
    });
  },
  postActivityMessage: async ({ state: _state, rootGetters }, { key }) => {
    if (!_state.conversation?.id) {
      return null;
    }
    const user = await rootGetters.getCurrentUser;
    const number = await _state.otherPartyNumber;
    const messageParams = {
      conversationId: _state.conversation.id,
      message: i18n.global.t(key, { user: user.name, number }),
      messageType: MESSAGE_TYPE.ACTIVITY,
    };
    log('Creating activity message', messageParams);
    return MessageApi.create(messageParams);
  },
};

export const mutations = {
  [types.default.SET_PHONE_UA](_state, ua) {
    if (_state.userAgent) {
      _state.userAgent.stop();
    }
    if (ua) {
      _state.userAgent = shallowRef(ua);
    } else {
      _state.userAgent = null;
    }
  },
  [types.default.SET_PHONE_SESSION](_state, { session, request }) {
    _state.session = shallowRef(session);
    _state.request = shallowRef(request);
    _state.callInfo = parseCallInfo(request);
    _state.micMuted = false;
    _state.speakerSilent = false;
    _state.holdStatus = null;
    _state.contact = null;
    if (session) {
      _state.otherPartyNumber = parseRemotePartyNumber(
        _state.callInfo,
        session,
        request
      );
    }
  },
  [types.default.SET_PHONE_KEEPALIVE_INTERVAL](_state, interval) {
    _state.keepAliveInterval = interval;
  },
  [types.default.SET_USER_PHONE_STATUS](_state, status) {
    _state.userRequestedStatus = status;
  },
  [types.default.SET_PHONE_STATUS](_state, status) {
    _state.status = status;
  },
  [types.default.SET_PHONE_STREAMS](_state, streams) {
    _state.streams = shallowRef(streams);
  },
  [types.default.SET_PHONE_CALL_TIMER](_state, timer) {
    _state.callTimer = timer;
  },
  [types.default.SET_PHONE_CALL_DURATION](_state, seconds) {
    _state.callDuration = seconds;
  },
  [types.default.SET_PHONE_HOLD](_state, status) {
    _state.holdStatus = status;
  },
  [types.default.SET_PHONE_SPEAKER_SILENT](_state, status) {
    _state.speakerSilent = status;
  },
  [types.default.SET_PHONE_MIC_MUTED](_state, status) {
    _state.micMuted = status;
  },
  [types.default.SET_PHONE_CONTACT](_state, contact) {
    _state.contact = contact;
  },
  [types.default.SET_PHONE_CONVERSATION](_state, conversation) {
    _state.conversation = conversation;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
